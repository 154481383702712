// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "breadcrumb";
// @import "pagination";
// @import "badge";
// @import "~bootstrap/scss/alert";
// @import "progress";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "tooltip";
// @import "popover";
// @import "carousel";
// @import "spinners";
// @import "offcanvas";
// @import "placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

html,
body {
    background-color: #eee;
    color: #1f1c1f;
    font-family: "Gotham", Arial;
}

#mainAltarCall {
    .container {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
    .field-wrapper {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
    }
}

.btn-success {
    background-color: #59ab0b;
}

#btnSubmitFormAltarCall {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2em;
    letter-spacing: 4px;
    font-weight: bold;
    border-radius: 0;
}
.header {
    background-color: #1c1c1c;
    padding: 15px;
    padding-top: 21px;
    color: white;
    text-align: right;
    .logo {
        width: 57px;
        position: absolute;
        left: 10px;
        top: -8px;
        margin-top: 1rem;
    }
}
